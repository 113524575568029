<template>
  <div class="text-center">
    <h3 class="mb-6 font-faktum">Sign In</h3>
    <form @submit.prevent="handleSubmit">
      <PulseInput
        id="email"
        ref="inputRef"
        v-model="email"
        :icon-left="EnvelopeIcon"
        name="email_address"
        placeholder="Enter your work email address"
        class="mb-4 text-sm"
        type="email"
      />
      <PulseButton class="w-full text-center" variant="primary" type="submit"> Continue </PulseButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import { EnvelopeIcon } from '@heroicons/vue/24/outline';

const email = ref('');
const inputRef = ref<any>(null);

const emit = defineEmits(['submit']);

function handleSubmit() {
  email.value.length && emit('submit', email.value);
}

onMounted(() => {
  inputRef.value?.input.focus();
});
</script>
